import React from 'react';
import PropTypes from 'prop-types';

import { MainStage } from '@ccg/fork';

import PageTemplate from '../templates/page';
import Embeds from '../../shared/Embeds/Embeds';

const Home = ({ data }) => {
  const { navigation, footer, seoDefaults, cookieLayer, entry } = data;

  return (
    <PageTemplate
      navigation={navigation}
      seoDefaults={seoDefaults}
      seo={entry}
      footer={footer}
      cookieLayer={cookieLayer}
    >
      <MainStage headline={entry.headline} video="/fork/videos/background-prisma-small.mp4" />

      <Embeds embedsCopy={entry.embedsCopy} embeds={entry.embeds} />
    </PageTemplate>
  );
};

Home.propTypes = { data: PropTypes.shape().isRequired };

Home.defaultProps = {};

export default Home;
